/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import JobOpening from "pages/AboutUs/sections/JobOpening";
import Information from "pages/AboutUs/sections/Information";
import Team from "pages/AboutUs/sections/Team";
import Featuring from "pages/AboutUs/sections/Featuring";
import Posts from "pages/AboutUs/sections/Posts";
import Contact from "pages/AboutUs/sections/Contact";
import Newsletter from "pages/AboutUs/sections/Newsletter";
import Gallery from "pages/AboutUs/sections/Gallery";
import Counters from "pages/AboutUs/sections/Counters";

// Routes
import routes from "routes";
import { footerRoutesTw } from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import contactBgImage from "assets/images/card/CBD-building.jpg";
import YoutubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";

// Translation
import { useTranslation } from "react-i18next";

// CSS
import "./index.scss";
import "./sass/tw.scss";
import "./sass/star.sass";

function AboutUsTw({ lang }) {
  const { t } = useTranslation();

  const currentLang = lang;

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      if (item[0] === ".") {
        images[item.replace("./", "")] = r(item);
      }
    });
    return images;
  };

  // Import Team Members imgs based on current lang
  const teamImages = importAll(
    require.context("../../assets/images/team/zh_TW", false, /\.(png|jpe?g|svg)$/)
  );
  const galleryImages = importAll(
    require.context("../../assets/images/gallery/zh_TW", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <>
      <MKBox
        className="main-container page--tw"
        minHeight="90vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <DefaultNavbar
          routes={routes.map((route) => ({
            ...route,
            name: t("index.nav.language"),
          }))}
          action={{
            type: "external",
            route: t("index.nav.jobs_link"),
            label: t("index.nav.jobs_link_label"),
            color: "default",
          }}
          currentLang={currentLang}
          transparent
          light
        />

        <Container>
          <div id="stars" />
          <div id="stars3" />
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              className="heading--main"
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                opacity: 0.8,
                letterSpacing: 5,
              })}
            >
              {t("index.banner.heading")}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mt={1}
              mb={3}
              sx={{ letterSpacing: 2 }}
            >
              {t("index.banner.subtitle")}
            </MKTypography>
            <a href="#contact-us-section">
              <MKButton
                className="btn-hiring"
                color="default"
                sx={{ color: ({ palette: { dark } }) => dark.main }}
              >
                <PermContactCalendarIcon sx={{ mr: 1 }}>favorite</PermContactCalendarIcon>
                {t("index.button.callout").toUpperCase()}
              </MKButton>
            </a>
            <MKBox display="flex" justifyContent="center" alignItems="center" mt={5}>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.linkedin")}
                target="_blank"
                mr={3}
              >
                <LinkedInIcon />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.job_link")}
                target="_blank"
                mr={3}
              >
                <WorkIcon />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.home_page")}
                target="_blank"
                mr={3}
              >
                <PublicIcon />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.youtube.com/channel/UC1mpIQbKvI37sLlDsxmcyeA "
                target="_blank"
              >
                <YoutubeIcon />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        className=" page--tw"
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <JobOpening />
        <Information />
        <Counters />
        <Featuring />
        <Posts />
        <Newsletter />
        <Team images={teamImages} />
        <Gallery images={galleryImages} />
        <Contact bgImage={contactBgImage} currentLang={lang} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutesTw} />
      </MKBox>
    </>
  );
}

AboutUsTw.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default AboutUsTw;
