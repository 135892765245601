/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import eCommerceImage from "assets/images/whatWeDo/eCommerce.jpg";
import iTConsultingImage from "assets/images/whatWeDo/ITConsulting.jpg";
import marketingImage from "assets/images/whatWeDo/marketing.jpg";

// Material Kit 2 React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// translation
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h2" mb={6}>
            {t("information.title")}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={eCommerceImage}
              title={t("information.commerceTitle")}
              description={t("information.commerceDescription")}
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={iTConsultingImage}
              title={t("information.marketTitle")}
              description={t("information.marketDescription")}
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={marketingImage}
              title={t("information.cxTitle")}
              description={t("information.cxDescription")}
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
