/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import jobSearch from "assets/images/job-search.svg";

const jobOpenings = [
  {
    position: "Java後端工程師",
    color: "slateblue",
    description: [
      "Java編程基礎，熟悉使用Spring、Spring Boot等開發框架。",
      "對程式開發具有熱情，願意嘗試新技術。",
    ],
  },
  {
    position: "前端工程師",
    color: "salmon",
    description: [
      "網頁技術類: 熟悉CSS、HTML、JavaScript，Angular。",
      "具備良好的溝通能力、團隊合作與較強的學習能力。",
    ],
  },
  {
    position: "全端工程師",
    color: "steelgray",
    description: [
      "網頁後端相關知識技術之研究與應用(具web API使用與API撰寫能力)",
      "具備團隊意識，設計、優化、操作資料庫系統。",
    ],
  },
  {
    position: "菁英實習招募",
    color: "orange",
    description: ["大四及二技二年級應屆畢業生(留任接軌就業)", "參予國際專案機會"],
  },
];

function JobOpening() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container item xs={12}>
          <img
            alt=""
            src={jobSearch}
            style={{ width: 150, height: "auto", display: "block", margin: "auto" }}
          />
        </Grid>
        <Grid container item xs={12} textAlign="center">
          <MKTypography variant="h2" width="100%" mt={3} mb={3} textAlign="center">
            招募職缺
          </MKTypography>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start">
          {jobOpenings.map((job) => (
            <Grid
              item
              key={job.position}
              xs={12}
              md={6}
              lg={3}
              sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}
            >
              <MKBox pt={{ xs: 1, lg: 2 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                <MKTypography
                  component="h5"
                  variant="body1"
                  sx={{ color: job.color }}
                  mr={3}
                  mb={2}
                >
                  {job.position}
                </MKTypography>
                <ul className="list--job-des">
                  {job.description.map((des) => (
                    <li key={des.split(5, 10)}>{des}</li>
                  ))}
                </ul>
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default JobOpening;
