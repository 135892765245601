import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Component
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import FsLightbox from "fslightbox-react";

// translation
import { useTranslation } from "react-i18next";

const Gallery = ({ images }) => {
  const { t } = useTranslation();

  const imageKeyArray = Object.keys(images);
  const imageSrcArray = Array.from(Object.values(images));
  const [toggler, setToggler] = useState(false);
  const [imgIndex, setImgIndex] = useState(1);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setToggler((prev) => !prev);
  }, [imgIndex]);

  const teamBadges = t("gallery.badges", { returnObjects: true }).map((data) => (
    <MKBadge badgeContent={data.name} color={data.color} variant={data.variant} container />
  ));

  return (
    <div className="gallery">
      <main>
        <div className="mkbadge-margin">{teamBadges}</div>
        <MKTypography variant="h1" textAlign="center" mb={2}>
          {t("gallery.title")}
        </MKTypography>
        <p>{t("gallery.description")}</p>
        <p>{t("gallery.ideas")}</p>
        <div className="pic-container">
          {imageSrcArray.map((imageSrc, index) => (
            <div
              key={`div-${Math.floor(Math.random() * 10000)}`}
              role="button"
              tabIndex={index}
              onClick={() => {
                setToggler(!toggler);
                setImgIndex(index);
              }}
              onKeyDown={() => {
                setToggler(!toggler);
                setImgIndex(index);
              }}
            >
              <img className="pic" src={imageSrc} alt={imageKeyArray[index]} />
            </div>
          ))}
        </div>
        <FsLightbox
          toggler={toggler}
          sourceIndex={imgIndex}
          sources={imageSrcArray}
          key={imgIndex}
        />
      </main>
    </div>
  );
};
Gallery.propTypes = {
  images: PropTypes.objectOf(PropTypes.string).isRequired,
};
export default Gallery;
