// Component
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import SimpleModal from "pages/AboutUs/sections/SimpleModal";
import MKTypography from "components/MKTypography";

// Image
import cardContactUs from "assets/images/card/kermit-phone.gif";

// Form
import { useForm } from "@formspree/react";

// translation
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm("xwkyalpw");

  // eslint-disable-next-line
  function ErrorMsg() {
    if (state.errors) {
      return state.errors.map((error) => (
        <p className="hint-text" key={`hint-${Math.floor(Math.random() * 10000)}`}>
          {error.message}
        </p>
      ));
    }
    return null;
  }

  return (
    <div className="call-to-action">
      <main>
        <div className="content">
          <MKTypography variant="h1" mb={2}>
            {t("newsletter.title")}
          </MKTypography>
          <p>{t("newsletter.description")}</p>
          <div className="action-container">
            <form method="POST" onSubmit={handleSubmit}>
              <MKInput
                label={t("newsletter.email")}
                type="email"
                id="email"
                name="email"
                required
              />
              <MKButton type="submit" size="large" color="warning">
                {t("newsletter.contact")}
              </MKButton>
              <SimpleModal isSubmitSuccess={state.succeeded} failMsg={state.errors} />
            </form>
            <ErrorMsg />
          </div>
        </div>
        <div className="img-container">
          <img src={cardContactUs} alt={t("newsletter.alt")} />
        </div>
      </main>
    </div>
  );
};
export default Newsletter;
