// @mui icons
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoSeidor from "assets/images/logos/seidor-logo-white-bg.png";

const date = new Date().getFullYear();

export const footerRoutesEs = {
  brand: {
    name: "Seidor logo",
    image: logoSeidor,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedinIcon />,
      link: "https://www.linkedin.com/company/seidor",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/seidor",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UC1mpIQbKvI37sLlDsxmcyeA",
    },
  ],
  menus: [
    {
      name: "Quiénes Somos",
      items: [
        { name: "Sobre Seidor", href: "https://www.seidor.com/es-es/sobre-seidor" },
        { name: "Noticias", href: "https://www.seidor.com/es-es/sala-de-prensa" },
        { name: "Blog", href: "https://www.seidor.com/es-es/blog" },
        { name: "Nuestras Oficinas", href: "https://www.seidor.com/es-es/nuestras-sedes" },
      ],
    },
    {
      name: "Servicios",
      items: [
        {
          name: "Customer Experience",
          href: "https://www.seidor.com/es-es/servicios/customer-experience",
        },
      ],
    },
    {
      name: "Principales Partners",
      items: [
        { name: "SAP", href: "https://www.sap.com/spain/index.html" },
        { name: "Commerce Tools", href: "https://commercetools.com/?location=emea" },
        { name: "Salesforce", href: "https://www.salesforce.com/es/" },
        { name: "Mirakl", href: "https://www.mirakl.com/" },
      ],
    },
    {
      name: "Contacto",
      items: [
        { name: "Linkedin", href: "https://www.linkedin.com/company/seidor/jobs/" },
        {
          name: "Trabaja en Seidor",
          href: "https://career2.successfactors.eu/career?company=seidorcons",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Todos los derechos reservados. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.seidor.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Seidor
      </MKTypography>
      .
    </MKTypography>
  ),
};

export const footerRoutesTw = {
  brand: {
    name: "SEIDOR APA",
    image: logoSeidor,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedinIcon />,
      link: "https://www.linkedin.com/company/seidor",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/seidor",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UC1mpIQbKvI37sLlDsxmcyeA",
    },
  ],
  menus: [
    {
      name: "關於我們",
      items: [
        { name: "關於SEIDOR", href: "https://www.seidor.com/zh-tw/about-seidor" },
        { name: "找到我們", href: "https://www.seidor.com/zh-tw/where-are-we-located" },
        { name: "工作機會", href: "https://www.seidor.com/zh-tw/talent" },
      ],
    },
    {
      name: "我們的服務",
      items: [
        {
          name: "客戶體驗",
          href: "https://www.seidor.com/zh-tw/services/customer-experience",
        },
        {
          name: "電子商務和市場",
          href: "https://www.seidor.com/zh-tw/solutions/eCommerce-and-marketplaces",
        },
        {
          name: "網路和應用程式開發",
          href: "https://www.seidor.com/zh-tw/solutions/web-and-app-development",
        },
      ],
    },
    {
      name: "合作夥伴",
      items: [
        { name: "SAP", href: "https://www.seidor.com/zh-tw/partners/sap" },
        { name: "Salesforce", href: "https://www.seidor.com/zh-tw/partners/salesforce" },
        { name: "Mirakl", href: "https://www.mirakl.com/" },
      ],
    },
    {
      name: "聯絡SEIDOR",
      items: [
        { name: "Linkedin", href: "https://www.linkedin.com/company/seidor/jobs/" },
        {
          name: "104公司頁面 ",
          href: "https://www.104.com.tw/company/1a2x6bl3fv",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      版權所有 Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.seidor.com/zh-tw"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Seidor APA
      </MKTypography>
      .
    </MKTypography>
  ),
};
