/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// translation
import { useTranslation } from "react-i18next";

function SimpleModal(submitStatus) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const { isSubmitSuccess } = submitStatus;

  useEffect(() => {
    if (isSubmitSuccess) {
      toggleModal();
    }
  }, [submitStatus]);

  // useEffect(() => {
  //   console.log(submitStatus);
  //   if (failMsg && failMsg.length > 0) {
  //     toggleModal();
  //   }
  // }, [submitStatus]);

  return (
    <Container>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox
              display="flex"
              alginItems="center"
              justifyContent="space-between"
              sx={{ padding: "24px 60px" }}
            >
              <MKTypography variant="h5" flexGrow="1" textAlign="center">
                {t("simplemodal.msg")}
                {/* {isSubmitSuccess ? "Mensaje Enviado!" : () => failMsg.map((error) => error.message)} */}
              </MKTypography>
              <CloseIcon
                fontSize="medium"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "5px",
                  top: "5px",
                  width: "24px",
                  height: "24px",
                }}
                onClick={toggleModal}
              />
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </Container>
  );
}

export default SimpleModal;
