/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post2 from "assets/images/card/testimonial.jpg";
import post3 from "assets/images/card/flat-organization.jpg";
import cardRemoteFirst from "assets/images/card/remote-first.jpg";
import cardFlexibleWork from "assets/images/card/flexible-work.jpg";
import cardDinosaursMeme from "assets/images/card/dinosaurs-meme.jpg";
import cardSeeTheWorld from "assets/images/card/see-the-world.jpg";
import cardExpertTeam from "assets/images/card/expert-team.jpg";
import cardNoDressCode from "assets/images/card/working-pijamas.jpg";
import cardOfficeSunset from "assets/images/card/office-sunset.jpg";

// translation
import { useTranslation } from "react-i18next";

function Places() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={2}>
      <Container className="post-component">
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h2" mb={6}>
            {t("posts.title")}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={cardRemoteFirst}
              title={t("posts.transparent_blogcard_1.title")}
              description={t("posts.transparent_blogcard_1.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title={t("posts.transparent_blogcard_2.title")}
              description={t("posts.transparent_blogcard_2.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title={t("posts.transparent_blogcard_3.title")}
              description={t("posts.transparent_blogcard_3.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={cardFlexibleWork}
              title={t("posts.background_blogcard_1.title")}
              description={t("posts.background_blogcard_1.description")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={3} mb={4}>
          <Grid item xs={12} sm={6} lg={3} mb={3}>
            <BackgroundBlogCard
              image={cardSeeTheWorld}
              title={t("posts.background_blogcard_2.title")}
              description={t("posts.background_blogcard_2.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={cardExpertTeam}
              title={t("posts.transparent_blogcard_4.title")}
              description={t("posts.transparent_blogcard_4.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={cardNoDressCode}
              title={t("posts.transparent_blogcard_5.title")}
              description={t("posts.transparent_blogcard_5.description")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={t("lang.locale") === "zh_TW" ? cardOfficeSunset : cardDinosaursMeme}
              title={t("posts.transparent_blogcard_6.title")}
              description={t("posts.transparent_blogcard_6.description")}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
