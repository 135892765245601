/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// translation
import { useTranslation } from "react-i18next";

function Team({ images }) {
  const { t } = useTranslation();
  const imageSrcArray = Array.from(Object.values(images));
  const teamMembers = t("team.team_members", { returnObjects: true }).map((teamMember, index) => (
    <Grid key={teamMember.name} item xs={12} lg={6}>
      <MKBox mb={{ xs: 1, lg: 0 }}>
        <HorizontalTeamCard
          image={imageSrcArray[index] || ""}
          name={teamMember.name}
          url={teamMember.linkedin}
          position={{ color: "info", label: teamMember.position }}
          description={teamMember.description}
        />
      </MKBox>
    </Grid>
  ));

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              {t("team.title")}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              {t("team.description")}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {teamMembers}
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the Team
Team.propTypes = {
  images: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Team;
