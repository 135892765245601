/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import sapcommerce from "assets/images/logos/gray-logos/sap-commerce.png";
import salesforce from "assets/images/logos/gray-logos/salesforce.png";
import adobe from "assets/images/logos/gray-logos/adobe.png";
import mirakl from "assets/images/logos/gray-logos/mirakl.png";
import qualtrics from "assets/images/logos/gray-logos/qualtrics.png";
import emarsy from "assets/images/logos/gray-logos/emarsy.png";

function Featuring() {
  return (
    <MKBox component="section" pt={3} pb={4}>
      <Container>
        <Grid className="filter" container spacing={3} sx={{ mb: 6 }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={sapcommerce}
              alt="sapcommerce"
              height="70px"
              width="auto"
              opacity={0.7}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={salesforce}
              alt="salesforce"
              height="90px"
              width="auto"
              opacity={0.7}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={adobe}
              alt="adobe"
              height="80px"
              width="auto"
              opacity={0.7}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={mirakl}
              alt="mirakl"
              height="90px"
              width="auto"
              opacity={0.7}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={qualtrics}
              alt="qualtrics"
              height="50px"
              width="auto"
              marginTop="6px"
              opacity={0.7}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={emarsy}
              alt="emarsy"
              height="40px"
              width="auto"
              opacity={0.7}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
