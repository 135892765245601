/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images

import YoutubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";

// Translation
import { useTranslation } from "react-i18next";

// Form
import { useForm } from "@formspree/react";
import SimpleModal from "pages/AboutUs/sections/SimpleModal";

function Contact({ bgImage, currentLang }) {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm("xrgjrpbr");
  const socialMedia =
    currentLang === "zh_TW" ? (
      <MKTypography
        component="a"
        variant="body1"
        color="white"
        href="https://www.youtube.com/channel/UC1mpIQbKvI37sLlDsxmcyeA "
        target="_blank"
      >
        <YoutubeIcon />
      </MKTypography>
    ) : (
      <MKTypography
        component="a"
        variant="body1"
        color="white"
        href="https://twitter.com/seidor"
        target="_blank"
      >
        <TwitterIcon />
      </MKTypography>
    );

  return (
    <MKBox component="section" id="contact-us-section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item className="contact-wrapper">
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      {t("contact.title")}
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      {t("contact.message_fillForm")}😉
                    </MKTypography>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {t("contact.contact_email")}
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {t("contact.contact_address")}
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      <MKTypography
                        component="a"
                        variant="body1"
                        color="white"
                        href={t("contact.linkedin")}
                        target="_blank"
                        mr={3}
                      >
                        <LinkedInIcon />
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="body1"
                        color="white"
                        href={t("contact.job_link")}
                        target="_blank"
                        mr={3}
                      >
                        <WorkIcon />
                      </MKTypography>
                      <MKTypography
                        component="a"
                        variant="body1"
                        color="white"
                        href={t("contact.home_page")}
                        target="_blank"
                        mr={3}
                      >
                        <PublicIcon />
                      </MKTypography>
                      {socialMedia}
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox
                  component="form"
                  p={2}
                  method="POST"
                  onSubmit={handleSubmit}
                  className="contact-form"
                >
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      {t("contact.message_initiateTalk.0")} 👋
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      {t("contact.message_initiateTalk.1")}
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          type="text"
                          name="userName"
                          id="userName"
                          variant="standard"
                          label={t("contact.form.name_label")}
                          placeholder={t("contact.form.name_placeholder")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          type="text"
                          name="favItem"
                          id="favItem"
                          variant="standard"
                          label={t("contact.form.love_label")}
                          placeholder={t("contact.form.love_placeholder")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          name="userMsg"
                          id="userMsg"
                          variant="standard"
                          label={t("contact.form.message_label")}
                          placeholder={t("contact.form.message_placeholder")}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                      className="action-container"
                    >
                      <MKButton variant="gradient" color="warning" type="submit">
                        {t("contact.button.sendMessage")}
                      </MKButton>
                      <SimpleModal isSubmitSuccess={state.succeeded} failMsg={state.errors} />
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

Contact.defaultProps = {
  currentLang: "es",
};

Contact.propTypes = {
  currentLang: PropTypes.string,
  bgImage: PropTypes.string.isRequired,
};

export default Contact;
