/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import AboutUsEs from "pages/AboutUs/index_es";
import AboutUsTw from "pages/AboutUs/index_tw";
import { useParams } from "react-router-dom";

export default function AboutUsPage() {
  const { lang } = useParams();
  return lang === "zh_TW" ? <AboutUsTw lang={lang} /> : <AboutUsEs lang={lang} />;
}
