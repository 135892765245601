/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/AboutUs/sections/Information";
import Team from "pages/AboutUs/sections/Team";
import Featuring from "pages/AboutUs/sections/Featuring";
import Posts from "pages/AboutUs/sections/Posts";
import Contact from "pages/AboutUs/sections/Contact";
import Newsletter from "pages/AboutUs/sections/Newsletter";
import Gallery from "pages/AboutUs/sections/Gallery";
import Counters from "pages/AboutUs/sections/Counters";

// Routes
import routes from "routes";
import { footerRoutesEs } from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us-elliot.png";
import contactBgImage from "assets/images/card/edificio-aqua.jpg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";

// Translation
import { useTranslation } from "react-i18next";

// CSS
import "./index.scss";

function AboutUsEs({ lang }) {
  const { t } = useTranslation();

  const currentLang = lang;

  const socialMedia = (
    <MKTypography
      component="a"
      variant="body1"
      color="white"
      href="https://twitter.com/seidor"
      target="_blank"
    >
      <TwitterIcon />
    </MKTypography>
  );

  const importAll = (r) => {
    const images = {};
    r.keys().forEach((item) => {
      if (item[0] === ".") {
        images[item.replace("./", "")] = r(item);
      }
    });
    return images;
  };

  // Import Team Members imgs based on current lang
  const teamImages = importAll(
    require.context("../../assets/images/team/es", false, /\.(png|jpe?g|svg)$/)
  );
  const galleryImages = importAll(
    require.context("../../assets/images/gallery/es", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <MKBox
        className="main-container"
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <DefaultNavbar
          routes={routes.map((route) => ({
            ...route,
            name: t("index.nav.language"),
          }))}
          action={{
            type: "external",
            route: t("index.nav.jobs_link"),
            label: t("index.nav.jobs_link_label"),
            color: "default",
          }}
          currentLang={currentLang}
          transparent
          light
        />
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {t("index.banner.heading")}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {t("index.banner.subtitle")}
            </MKTypography>
            <a href="#contact-us-section">
              <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                <AddShoppingCartIcon sx={{ mr: 1 }}>favorite</AddShoppingCartIcon>
                {t("index.button.callout").toUpperCase()}
              </MKButton>
            </a>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              {t("index.banner.find_us")}
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.linkedin")}
                target="_blank"
                mr={3}
              >
                <LinkedInIcon />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.job_link")}
                target="_blank"
                mr={3}
              >
                <WorkIcon />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href={t("index.banner.home_page")}
                target="_blank"
                mr={3}
              >
                <PublicIcon />
              </MKTypography>
              {socialMedia}
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Counters />
        <Featuring />
        <Posts />
        <Newsletter />
        <Team images={teamImages} />
        <Gallery images={galleryImages} />
        <Contact bgImage={contactBgImage} currentLang={currentLang} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutesEs} />
      </MKBox>
    </>
  );
}

AboutUsEs.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default AboutUsEs;
