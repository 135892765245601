/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "App";
import "./i18n/config";

const defaultLang = `/${localStorage.getItem("i18nextLng") || "es"}`;

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:lang/*" element={<App />} />
      <Route path="/*" element={<Navigate to={defaultLang} />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
